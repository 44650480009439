@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.josefin-sans-header {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100; 
    font-style: normal;
  }
  .general_overview{
    background-color:#1daf38;
  }
.size{
    font-size:40px !important;
}
.texLeft{
    text-align:left !important;
}
.underline_title{
    border-bottom:1px solid #cfd3d0 !important;
}
.bg1{
    background-color:#73b0ce;
    /* #63b6e2;  */
    /* #94c5e0; */
  
}
.bg2{
    background-color: #82b28b;
}
.bg3{
    background-color: #209339;
}
.bg4{
    background-color: #82b28b;
    /* #9ebca4;  */
    /* 86b9d6; */
}
.bg1, .bg2, .bg3, .bg4{
    margin: 0px !important;
    padding:10px !important;
    border-radius: 0px !important;
    margin-top:10px !important;
    padding-left:20px !important;
    padding-right:20px !important;
    padding-bottom:0px !important;
    color: #fff;
}
  
.side_bar{
    background-color: #EBF1EB !important;
}
.logout{
    color: #fff;
    /* color: #A65959; */
    cursor: pointer;
}

.card_overview{
    font-weight: 650;
}
.no_raduis{
    border-radius: 0px !important;
}
.bg_success{
    background-color:#3c8dbc;
    /* background-color:#3c8dbc; //blue*/
    /* background-color:#F9FFF4; */
}
.text_success{
    color: #3c8dbc ;
    /* #3c8dbc; */
}
.border_right{
    border-right: 1px solid black;
}
.analytics_padding{
    padding-left: 50px !important;
    padding-top: 15px;
}
.homepage{
    background-color: #FFFFFF;
    color: #000000;
    margin: 10px 30px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    color: #000000;
    display: inline-block; 
    text-decoration: none !important;
}
.navItem{
    color: #000000;
    margin-left: 23%;
    border-left: 2px solid #000000;
    padding-left: 8%;
    margin-bottom:50px;
    cursor: pointer;
}
.space{
    height:80px !important;
}
.move_right{
    justify-content: right;
}
.align_right{
    text-align: right !important;
}
.btn_background{
    background-color: #3c8dbc !important;
}
.navItems{
    color: #000000;
    padding: 10px;
    margin-left: 23%;
    border-left: 2px solid #fff !important;
    padding-left: 8%;
    cursor: pointer;
}
.align_left{
    position: absolute !important;
    left:0px;
    color: #000;
    padding: 5px 10px;
    border-radius:10px;
}
.navItemWithoutBorder{
    color: #000000;
    padding: 2px;
    margin-left: 23%;
    padding-left: 8%;
    cursor: pointer;
}
.active{
    color: #3c8dbc !important;
    background-color: #FFFFFF;
    font-weight: 700;
    width:100%;
}
.loader{
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 20vh;
    position: absolute;
    top: 25%;
    left:45%
}
.dashboard_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #e8ede9; */
    z-index: 30;
}
.navItems:hover, .navItem:hover, .navItemWithoutBorder:hover{
    background-color: #3c8dbc;
    color: #FFFFFF;
}
.administrative{
    font-size: 16px;
    margin: 10px 40px;
    font-weight: 600;
    margin-top: 30px;
}
.help{
    width: 100% !important;
    padding-left: 25%;
    margin-bottom: 0px;
    font-weight: 650;
}
.float_right{
    float: right !important;
}
.user_detail_wrapper{
    padding-top: 18px ;
    color: #183153;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 17px;
    margin-right:50px !important;
}
.support_icon{
    color: #000000;
    margin-left: 15%;
    padding-left: 8%;
    margin-bottom:50px;
    cursor: pointer;  
}
.light_bg{
    position: absolute;
    width: 80vh;
    height: 1690px;
    left: 865px;
    top: -917px;
    background: rgba(3, 135, 0, 0.37);
}
.light_green{
    background-color: #FAFFFC;
}
.buttons{
    background-color: #3c8dbc !important;
    color: #FFFFFF !important;
    cursor: pointer !important;
    margin-left: 20px;
}
.buttons_outline{
    background-color: #FFFFFF !important;
    color: #3c8dbc !important;
    cursor: pointer !important;
    margin-left: 20px;
    border: 1px solid #3c8dbc !important;
}
.buttons:hover{
    color: #000000 !important;
}
.buttons_outline:hover{
    color: #FFFFFF !important;
    background-color: #3c8dbc !important;
}
.theme_text_color{
    color: #3c8dbc;
  }

  .center-container {
    /* margin-top: 20%; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
  }
  
  .centered-div {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  } 
  
/* Modals Start */
.modal_btn{
    background-color: #3c8dbc !important;
    color: #FFFFFF !important;
    width: 100%;
  }
.filter_btns{
    margin: 8px;
    width: auto
    /* height: -webkit-fill-available; */
}
.border_bottom{
    border-bottom: 1px solid #575151;
    padding-bottom: 0px !important;
    margin-bottom: 6px;
}
.back{
    margin-top: 40px;
    font-weight: 600;
    color: #3c8dbc;
    text-decoration: underline;
    cursor: pointer;
}

.facility_name_th {
    width: 400px !important;
  }
.modal_btn:hover{
    color: black !important;
    background-color: #3c8dbc !important;
}
.modal_header_text{
    font-size: 19px;
    font-weight: 700;
}
.modal_content .modal_header {
    display: flex;
    justify-content: space-between;

}
.support_modal_top{
    height: 90px;
    background-color: #3c8dbc;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    color: #FFFFFF;
}
.support_modal_header_text{
    text-align: left;
    width: 100%;
    margin-top: 13px;
    padding-left: 8%;
}
.other_modal_top{
    height: 90px;
    background-color: #3c8dbc;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    color: #000000;
}
.other_modal_header_text{
    text-align: left;
    width: 100%;
    margin-top: 13px;
    padding-left: 8%;
}
.close_support_modal{
    margin-right: 8% !important;
    margin-top: 13px;
}
.support_form{
    padding: 8%;
}
.supportAttachment{
    border: 1px dashed black;
    color: #6C6363;
    border-radius: 5px;
    background-color: #FFFFFF;
    width: 100%;
}
.mobile_modal_size {
    width: 90%;
    max-width: 350px;
  }
.admin_badge{
    background-color: #3c8dbc;
    padding: 3px;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 10px;
    width: fit-content;
}
/* Modals Ends */

.general_buttons{
    background-color: #3c8dbc !important;
    color: #FFFFFF !important;
}
.general_buttons:hover{
    color: black !important;
    background-color: #3c8dbc !important;
}

.bg_green{
    background-color: #3c8dbc !important;
    color: #FFFFFF;
}

.bg_green:hover{
    color: #eeeeee !important;   
}
.theme_link{
    color: #3c8dbc !important;
    cursor: pointer !important;
    text-decoration: underline !important;
}
.theme_text{
    color: #3c8dbc !important;
}
.css-1c5w2no-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 17.5px 14px;
}
.light_card_background{
    background-color: #f2f2f2;
}
.sweet_alert_true{
    background-color: #3c8dbc !important;
    color: #ffffff !important;
}
.sweet_alert_false{
    background-color: red !important;
    color: #ffffff !important;
}
.badge_success, .badge_danger{
    color: #3c8dbc;
    background-color: #c9efc9;
    width: auto;
    border-radius: 15%;
    padding: 5px;
}
.badge_danger{
    color: #f70000;
    background-color: #f2d5d5;
}
.audit-log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.audit-log-back-button{
    color: #3c8dbc;
    cursor: pointer;
    text-decoration: underline;
}

.card_title_background{
    background-color: #EBF1EB;
    margin-bottom: 0px !important;
    font-weight: 600;
    padding: 20px;
}
tr:hover{
    background-color: #F4F6F8;
}
.title {
    /* background-color: #FFFFFF; */
    padding: 3px;
    color: #fff;
    font-weight: bolder !important;
    width: fit-content;
    text-align: center;
}
.profile_picture{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 5;
    top: 35%;
    left: 54%;
}
.navLink, .navItemWithoutBorder{
    color: #fff;
    display: inline-block;
    width: 100%;
    margin-left: 23%;
    border-left: 2px solid #000000;
    padding-left: 8%;
    margin-top: 0px !important;
    cursor: pointer;
    text-decoration: none !important;
}

.navLink:active{
    color: #3c8dbc !important;
    background-color: #FFFFFF;
    font-weight: 700;
    width:100%;
}
.active-button{
    background-color:#3c8dbc !important;
    color:#fff !important
}
.nonactive-button{
    color:#3c8dbc !important

}
.navLink:hover{
    color: #ffffff !important; 
}
@media only screen and (min-width : 1224px) {
    .multiple_select_lg_size{
        margin-top: -14px !important;
    }
}
/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    .multiple_select_lg_size{
        margin-top: -14px !important;
    }
    .hide{
        display: none !important;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .hide{
        display: none !important;
    }
    .profile_picture{
        height: 150px;
        width: 150px;
        border-radius: 50%;
        background-color: #FFFFFF;
        position: absolute;
        z-index: 5;
        top: 20%;
        left: 40%;
    }
    .align_left{
        position: static !important;
        color: #000;
        padding: 5px 10px;
        border-radius:10px;
    }
}
@media (min-width: 1600px) { 
    .profile_picture{
        height: 150px;
        width: 150px;
        border-radius: 50%;
        position: absolute;
        z-index: 5;
        top: 20%;
        left: 50%;
    }
}
  @media only screen and (max-width: 767px) {
    .hide{
        display: none !important;
    }
    .space{
        height:0px !important;
    }
    .loader{
        display: flex; 
        justify-content: center;
        align-items: center; 
        height: 20vh;
        position: absolute;
        top: 60%;
        left:40%
    } 
    .profile_picture{
        height: 150px;
        width: 150px;
        border-radius: 50%;
        background-color: #FFFFFF;
        position: absolute;
        z-index: 5;
        top: 25%;
        left: 30%;
    }
    .border_right{
        border-right: none !important;
    }
    .total_ward{
        margin-left:30px;
    }
    .margin_top{
        margin-top: 20px !important;
    }
    .align_left{
        position: static !important;
        color: #000;
        padding: 5px 10px;
        border-radius:10px;
        line-height:20px;
        font-size: 13px;
    }
  }
  @media only screen and (min-device-width: 1280px) 
  and (max-device-width: 1280px) 
  and (min-device-height: 800px) 
  and (max-device-height: 800px) {
    .hide{
        display: none !important;
    }
    .hide_on_mobile{
        display: none;
    }
    .nav_logo{
        position:fixed !important;
        top: 30px !important;
        left: 56% !important;
    }
    
  }
  
  
/* src/Tab.css */
  .tab-buttons {
    display: flex;
    /* justify-content: space-around; */
    width: 40% !important;
  }
  /* .css-ysycdn-MuiInputBase-root-MuiOutlinedInput-root{
    width: 143% !important;
    border:1px solid #797f8a !important ;
  } */
  .tab-buttons button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    background-color: #f2f2f2;
    cursor: pointer;
    /* width: 40% !important; */
  }
  
  .tab-buttons button.active {
    padding: 0px 5px !important;
    background-color: #d2d8d2;
    color: #3c8dbc;
    width: 40% !important;
  }
  
  .tab-content {
    margin-top: 10px;
  }
  
  .reconciliation-top{
    background-color: #e6f6d1;
    width: 100% !important;
    margin-bottom: 20px;
  }
.nav_logo{
    position:fixed;
    top: 30px;
    left: 6%;
}

.filter-select-right{
    width:200px !important;
}
/* .red-tile-layer{
    background-color:#6C6363 !important;
    height: 100vh !important;
    width: 100% !important;
    z-index: 40 !important;
} */
.custom-map-container {
    /* position: relative; */
    /* height: 100vh; */
    /* width: 100%; */
    background-color:#fff !important;
  }
  
  /* .red-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c0c1c5; 
    z-index: 400;
    opacity:0.2
  } */
  .dropdown-menu-left {
    left: 0 !important;
    right: auto !important;
  }

  .backgoundColor{
    background-color: #3c8dbc !important; 
    background-image: url('https://manoreporters.com/wp-content/uploads/2023/08/PDF-111.jpg'); 
    background-position: center; 
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    height: 100vh;
  }
  