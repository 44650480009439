.password_changed_success{
    height: 100px;
    background-color: #ebf1eb;
    border-radius: 50%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div_container{
    height: 100% !important;
    width: 100% !important;
    margin: 60px 0px 0px 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow-y: hidden !important;
}

.password_changed{
    color: #3c8dbc;
    font-size: 20px;
    font-weight: 700;
}