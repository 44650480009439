.header_container{
    background-color: #3c8dbc;
}
.header_text{
    margin-top: 5px;
    font-weight: 700;
    font-size: 20px;
}

.table {
    width: 100% !important;
}

.thead  th {
    border-bottom: 1px solid #EAECF0 !important;
    border-top: 1px solid #EAECF0 !important;
    color: #6B7280 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    padding: 16px !important;
    padding-left: 23px !important;
    padding-right: 9px !important;
}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #6B7280;
    padding-left: 23px;
    padding-right: 9px;   
}

.tr {
    height: 77px !important;
    border-bottom: 1px solid #b3b7b4;

}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #6B7280;
}