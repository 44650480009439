.header_container{
    background-color: #3c8dbc;
    font-weight: 700;
    font-size: 20px;
}
.header_text{
    margin-top: 5px;
    font-weight: 700;
    font-size: 20px;
}
.card {
    background: #FFFFFF !important;
    border: 1px solid #E5E7EB !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
}
img {
    display: inline !important;
}
.numbers {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

th{
    background-color: #F4F6F8;
}
.pagination_button_container button {
    background: #FFFFFF;
    
    border: 1px solid #D0D5DD;
    
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
}

.table {
    width: 100% !important;
}

.thead  th {
    border-bottom: 1px solid #EAECF0 !important;
    border-top: 1px solid #EAECF0 !important;
    color: #6B7280 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    padding: 16px !important;
    padding-left: 23px !important;
    padding-right: 9px !important;
}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #6B7280;
    padding-left: 23px;
    padding-right: 9px;   
}

.tr {
    height: 77px !important;
    border-bottom: 1px solid #EAECF0;

}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #6B7280;
}

.card_background{
    background-color: #3c8dbc;
    margin-bottom: 0px !important;
    border-bottom: 2px solid #FFFFFF;
    font-weight: 600;
}
.card_background1{
    background-color: #d6f0ff;
    margin-bottom: 0px !important;
    font-weight: 600;
}
.export_btn{
    background-color: #A95ABD !important;
    color: #FFFFFF !important;
}
.align_margin{
    margin-top: 15px !important;
}




/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    .align_margin{
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .align_margin{
        margin-top: 15px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .align_margin{
        margin-top: 15px !important;
    }
  }
  @media only screen and (min-device-width: 1280px) 
  and (max-device-width: 1280px) 
  and (min-device-height: 800px) 
  and (max-device-height: 800px) {
    .align_margin{
        margin-top: 15px !important;
    }
  }
