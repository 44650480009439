.align_margin{
    margin-top: 15px !important;
}
/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    .align_margin{
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .align_margin{
        margin-top: 15px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .align_margin{
        margin-top: 15px !important;
    }
  }
  @media only screen and (min-device-width: 1280px) 
  and (max-device-width: 1280px) 
  and (min-device-height: 800px) 
  and (max-device-height: 800px) {
    .align_margin{
        margin-top: 15px !important;
    }
  }


.table {
    width: 100% !important;
    font-size:13px !important;
}

.thead  th {
    border-bottom: 1px solid #EAECF0 !important;
    border-top: 1px solid #EAECF0 !important;
    color: #3c8dbc !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    padding: 10px !important;
    padding-left: 23px !important;
    padding-right: 9px !important;
    font-size:13px !important;
}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #3c8dbc;
    padding-left: 23px;
    padding-right: 9px; 
    font-size:13px !important;  
}

.tr {
    height: 47px !important;
    border-bottom: 1px solid #b3b7b4;

}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #3c8dbc;
}
.card-fixed-height {
    height: 400px !important;
    overflow-y: auto !important;
}